// Dependencies
import 'babel-polyfill'
import { _ } from './utils'
import svg4everybody from 'svg4everybody'
import 'url-search-params-polyfill';
import 'parsleyjs'

// Classes
import ParticipantToggle from './modules/participant-toggle'
import InputPassword from './modules/input-password'
import Accordion from './modules/accordion'
import GtmCall from './modules/gtm'
import InputUpload from './modules/input-upload'
import Slider from './modules/slider'
import Modal from './modules/modal'
import LazyLoad from './modules/lazy-load'
import PostcodeLookup from './modules/postcode-lookup'
import PostcodeLookup2 from './modules/postcode-lookup2'
import Participants from './modules/participants'
import Carousel from './modules/carousel'
import Reminders from './modules/reminders'
import NavToggle from './modules/nav-toggle'
import SubnavToggle from './modules/subnav-toggle'
import Toggle from './modules/toggle-class'
import Parsley from './modules/parsley'
import FeedbackQuestion from './modules/feedback-question'
import Tabs from './modules/tabs'
import Video from './modules/video'
import Listing from './modules/listing'
import Form from './modules/form'
import ObjectFit from './modules/object-fit'
import lazysizes from 'lazysizes'
import { accordionContent } from './modules/accordion-content'
import { shopsMap } from './modules/shops-map'

// Modules
_('participant-toggle').el().map((i, el) => new ParticipantToggle(el, i))
_('toggle-password').el().map((i, el) => new InputPassword(el, i))
_('accordion').el().map((i, el) => new Accordion(el, i))
_('gtm-call').el().map((i, el) => new GtmCall(el, i))
_('input-upload').el().map((i, el) => new InputUpload(el, i))
_('slider').el().map((i, el) => new Slider(el, i))
_('modal').el().map((i, el) => new Modal(el, i))
_('image', 'lazyload').el().map((i, el) => new LazyLoad(el, i))
_('postcode-lookup').el().map((i, el) => new PostcodeLookup(el, i))
_('postcode-lookup2').el().map((i, el) => new PostcodeLookup2(el, i))
_('card-carousel').el().map((i, el) => new Carousel(el, i))
_('nav-toggle').el().map((i, el) => new NavToggle(el, i))
_('subnav-toggle').el().map((i, el) => new SubnavToggle(el, i))
_('feedback-question').el().map((i, el) => new FeedbackQuestion(el, i))
_('tabs').el().map((i, el) => new Tabs(el, i))
_('video').el().map((i, el) => new Video(el, i))
_('parsley', 'validate').el().parsley()
_('listing').nodes().map((el) => new Listing(el))
_('form').nodes().map((el) => new Form(el))
_('object-fit-cover', 'polyfill').nodes().map((el) => new ObjectFit(el, 'cover'))
_('object-fit-contain', 'polyfill').nodes().map((el) => new ObjectFit(el, 'contain'))
Array.from(document.querySelectorAll('.js-events-slider')).map(el => new Slider(el))
accordionContent()
shopsMap()
stickyBtn()

const participants = new Participants()
const reminders = new Reminders()
const parsley = new Parsley()

// Touch Detection
$(document).on('touchstart', () => {
    $('html').removeClass('no-touch').addClass('touch')
})

// External SVG Polyfill
svg4everybody({ nosvg: false })

$('input[data-input="phone"]').on('change', function() {
    $(this).val(function(_, v){
        return v.replace(/\s+/g, '');
    });
})

$(document).on('click', '[data-behaviour="events-notification"]', (e) => {
    const parentForm = $(e.currentTarget).parents('[data-element="events-notification-form"]')
    const inputs = $(parentForm).find('input')
    const action = parentForm.data('action')

    const obj = {}
    const valid = []

    inputs.map((i, el) => {
        if (el.name === 'email' || el.name === 'postcode') {
            if (el.value.length !== 0) {
                obj[el.name] = el.value
                valid.push('true')
            } else {
                valid.push('false')
                $(el).addClass('parsley-error')
            }
        } else if (el.name === 'terms') {
            if (el.checked === true) {
                obj[el.name] = el.value
                valid.push('true')
            } else {
                valid.push('false')
                $(el).parent().addClass('parsley-error')
            }
        }
    })

    inputs.on('change', (el) => {
        if (el.currentTarget.value.length > 0) {
            $(el.currentTarget).removeClass('parsley-error')
        }
    })

    if (!valid.includes('false')) {
        $.ajax({
            url: action,
            method: 'POST',
            headers: {
                'x-api-key': _('csrf', 'element').el().attr('content')
            },
            data: obj,
            dataType: 'json',
            success: (response) => {
                $('[data-element="events-notification-messages"]').removeClass('u-error')
                $('[data-element="events-notification-messages"]').addClass('u-success')
                $('[data-element="events-notification-messages"]').html(response.message)
                inputs.map((i, el) => {
                    el.removeAttribute('required')
                    el.value = ''
                    el.checked = false
                })
            },
            error: (err) => {
                $('[data-element="events-notification-messages"]').removeClass('u-success')
                $('[data-element="events-notification-messages"]').addClass('u-error')
                let errorsList = ''
                const errors = err.responseJSON.errors
                Object.values(errors).map(x => {
                    errorsList += `<br />${x}`
                })
                $('[data-element="events-notification-messages"]').html(errorsList)

            }
        })
    }
})
function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
}

function waitForElm(selector) {
    return new Promise(resolve => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(mutations => {
            if (document.querySelector(selector)) {
                observer.disconnect();
                resolve(document.querySelector(selector));
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
}

// Removes code from forms after recite.me removes all the css
waitForElm('.recite-controls-simplified').then((elm) => {
    const textModeButton = document.getElementById("recite-toolbar-button-text-mode");
    const encodedString = getCookie("Recite.Preferences")
    const decodedString = decodeURIComponent(encodedString);
    const jsonObject = JSON.parse(decodedString);
    const textModeValue = jsonObject.textMode.enabled;
    if (textModeValue === true){
        setTimeout(function() {
            console.log("removed");
            document.getElementById("reciteme-form-hide").style.display = "none";
        }, 1000);
    }
    textModeButton.addEventListener("click", function() {
        // Get the header__top div
        const encodedString = getCookie("Recite.Preferences")
        const decodedString = decodeURIComponent(encodedString);
        const jsonObject = JSON.parse(decodedString);
        const textModeValue = jsonObject.textMode.enabled;    
        if (textModeValue === false){
            setTimeout(function() {
                console.log("removed");
                document.getElementById("reciteme-form-hide").style.display = "none";
            }, 1000);
        } else {
            window.location.reload();
        }
    });
});

function stickyBtn() {
    const el = document.querySelector('.js-sticky-btn')
    if (!el) {
        return
    }

    const btn = el.querySelector('a')
    const url = new URL(btn.href)
    const target = document.querySelector(url.hash)

    if (!target) {
        return
    }

    let top = 0

    function updateTop() {
        const cbr = target.getBoundingClientRect()
        top = cbr.top + window.scrollY - window.innerHeight
    }
    updateTop()

    window.addEventListener('resize', updateTop, {passive: true})

    function checkIsHidden() {
        const scroll = window.scrollY
        if (scroll > top) {
            el.classList.add('is-hidden')
        } else {
            el.classList.remove('is-hidden')
        }
    }

    checkIsHidden()
    window.addEventListener('scroll', checkIsHidden, {passive: true})

    btn.addEventListener('click', (evt) => {
        evt.preventDefault()

        target.scrollIntoView({
            behavior: 'smooth'
        })
    })
}
