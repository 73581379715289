const select = (data) => {
    let paginationHTML = ''
    for (let i = 1; i <= data.totalPages; i++) {
        paginationHTML += `<option value="${i}" ${
            data.currentPage === i ? 'selected' : ''
        }>${i}</option>`
    }
    return paginationHTML;
}
const template = (data) => `
    <div class="pagination__col u-df u-align-items-center">
        ${data.currentPage !== 1 ? `<button class="link link--uppercase link--small" data-behaviour="prev">
            <svg width="26" height="26" class="link__icon link__icon--left">
                <use xlink:href="/application/themes/rawnet/app/images/sprite.svg#arrow"></use>
            </svg>
            previous page
        </button>` : ``}
    </div>
    <div class="pagination__col pagination__selection">
        <label for="pagination">
            Page
        </label>
        <div class="select select--solid u-mh-half u-mb0">
            <select class="select__select" data-behaviour="select">
                ${select(data)}
            </select>
        </div>
        of&nbsp;
        <span>${data.totalPages}</span>
    </div>
    <div class="pagination__col text--uppercase text--brand text--small">
        ${data.currentPage !== data.totalPages ? `<button class="link link--uppercase link--small u-df u-align-items-center" data-behaviour="next">
            next page
            <svg width="26" height="26" class="link__icon">
                <use xlink:href="/application/themes/rawnet/app/images/sprite.svg#arrow"></use>
            </svg>
        </button>` : ``}
    </div>
`
export default template
