import { _ } from '../utils'

export default class InputPassword {
    // TODO - Should only affect the confirmation field next to it, not all on the page
    constructor(el) {
        this.el = $(el)
        this.confirmation = $('[data-confirmation]')
        this.icon = {
            'show': '.input-password__toggle--show-password',
            'hide': '.input-password__toggle--hide-password'
        }

        this.el.on('click', this.togglePassword)
    }

    togglePassword = (e) => {
        const target = $(e.currentTarget).data('target')
        const input = $('[data-element="' + target + '"]').find('input')

        if (input.attr('type') === 'password') {
            input.attr('type', 'text')
            this.confirmation.attr('type', 'text')
            this.confirmation[0].required = false
        } else {
            input.attr('type', 'password')
            this.confirmation.attr('type', 'password')
            this.confirmation[0].required = true
        }

        $(this.icon.show).toggle()
        $(this.icon.hide).toggle()

        if (input.val().length > 0) {
            $('form').parsley().validate('confirmation')
        }
    }
}
