import {_} from '../utils'

export default class SubnavToggle {
    constructor(el) {
        this.el = $(el)
        this.target = this.el.data('target')
        this.el.on('click', this.toggleNav)
    }

    toggleNav = (e) => {
        e.preventDefault()
        _(this.target, 'element').el().toggleClass('primary-nav__subnav--active')
    }
}
