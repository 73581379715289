import { _ } from '../utils'
import Accordion from './accordion'
import PostcodeLookup from './postcode-lookup'

export default class Participants {

    constructor() {
        this.accordion = _('accordion').el()
        this.participant = _('participant', 'element').el()
        this.participantCount = this.participant.length
        this.saveParticipants = _('save-participants', 'element').el()
        this.participantToggle = _('participant-toggle').el()
        this.showAll = _('participant-show-all', 'element').el()
        this.existingParticipants = _('existing-participant', 'element').el()
        this.groupOne = _('1', 'element').el()
        this.groupTwo = _('2', 'element').el()
        this.parentIndex = 1
        this.childIndex = 1

        // If returning to Participants via Summary, hide the buttons and show accordions
        if (window.location.href.indexOf('?participant=') > -1) {
            this.participantToggle.hide()
            this.showAll.show()
        }

        this.conditionalFields()

        $(document).on('click', _('add-participant').str(), this.addParticipant)
        $(document).on('click', _('remove-participant').str(), this.removeParticipant)
    }

    conditionalFields = (e) => {
        this.accordion.find('[data-element="1"]').hide()
        this.accordion.find('[data-element="2"]').hide()

        if (_('selection-type', 'element').el().val() === '1') {
            this.accordion.find('[data-element="1"]').show()
            this.accordion.find('[data-element="2"]').remove()
        } else {
            this.accordion.find('[data-element="2"]').show()
        }
    }

    ordinalIndicator = (number, type) => {
        const s = ['th','st','nd','rd']
        let num = 0
        if (this.participant > 0) {
            if (type === 'parent') {
                num = this.parentIndex + this.participant.length
            } else if (type === 'child') {
                num = this.childIndex + this.participant.length
            }
        } else {
            if (type === 'parent') {
                num = this.parentIndex + this.existingParticipants.length
            } else if (type === 'child') {
                num = this.childIndex + this.existingParticipants.length
            }
        }
        const suffix = (s[(num-20)%10]||s[num]||s[0])

        return suffix;
    }

    addParticipant = (e) => {
        this.existingParticipants.length = this.participantCount || this.existingParticipants.length
        const { dataset: { type, parentId, save }} = e.currentTarget
        const container = $(e.currentTarget).closest('[data-element="repeater-' + type + '"]')
        const selectionType = $('[data-element="selection-type"]').val()

        let template = ''

        if (type === 'child') {
            template = $('[data-template="' + type + '"]').text().replace(/__id__/g, parentId).replace(/__child_id__/g, this.childIndex).replace(/__number_suffix__/g, this.ordinalIndicator(this.childIndex, 'child'))
            this.childIndex++
        } else {
            if (this.participantCount === 0) {
                template = $('[data-template="' + type + '"]').text().replace(/__id__/g, this.parentIndex + this.existingParticipants.length).replace(/__child_id__/g, this.childIndex).replace(/__number_suffix__/g, this.ordinalIndicator(this.parentIndex, 'parent'))
            } else {
                template = $('[data-template="' + type + '"]').text().replace(/__id__/g, this.parentIndex + this.existingParticipants.length).replace(/adult\[.\]/g, `new_adults[new_adult_${this.parentIndex + this.existingParticipants.length}]`).replace(/__child_id__/g, this.childIndex).replace(/__number_suffix__/g, this.ordinalIndicator(this.parentIndex, 'parent'))
            }

            this.parentIndex++
        }

        if (this.participant.length > 0) {
            if (type === 'child') {
                if (e.currentTarget.previousElementSibling === null) {
                    container.prepend(template)
                } else {
                    $(e.currentTarget.previousElementSibling).after(template)
                }
            } else {
                const last = this.participant.length - 1
                this.participant.last().after($(template)[0])
            }
        } else {
            container.prepend(template)
        }

        if (save) {
            this.saveParticipants.removeClass('u-hide')
        }

        if (_('participant', 'element').el().length > 0) {
            _('day-planning-button', 'element').el().removeClass('u-hide')
        }

        this.conditionalFields()
        this.reinitialiseComponents()
    }

    removeParticipant = (e) => {
        const { dataset: { type }} = e.currentTarget

        if (type === 'child') {
            $(e.currentTarget).closest(_('participantChild', 'element').str()).remove()
        } else {
            $(e.currentTarget).closest(_('participant', 'element').str()).remove()
        }

        if (_('participant', 'element').el().length === 0) {
            _('day-planning-button', 'element').el().addClass('u-hide')
        }

        // Set new participants list
        this.participant = _('participant', 'element').el()

        if (this.participant.length === 0) {
            this.saveParticipants.addClass('u-hide')
        }
    }

    reinitialiseComponents = (e) => {
        // Reinitialise components for newly generated items
        _('accordion').el().map((i, el) => new Accordion(el, i))
        _('postcode-lookup').el().map((i, el) => new PostcodeLookup(el, i))

        // Set new participants list
        this.participant = _('participant', 'element').el()

    }
}
