import {_} from '../utils'

export default class Parsley {
    constructor() {
        // Custom parsley errors for accordions
        window.Parsley.on('field:error', function() {
            this.$element.closest('[data-element="participant"]').addClass('accordion__item--error');
        });
    }
}
