const classes = {
    active: 'is-active',
    container: 'js-accordion-container'
}

const AccordionList = {
    el: null,
    closeOtherItems: true,
    items: []
}

const Accordion = {
    el: null,
    btn: null,
    focusableItems: [],
    container: null,
    innerFocusOpen: false,
    isOpen: false,
    animationFrame: null,
    parent: null
}

export function accordionContent(context = document) {
    const accordionListEls = context.querySelectorAll('.js-accordion-list')
    const accordionLists = Array.from(accordionListEls)
    return accordionLists.map(createAccordionList)
}

function createAccordionList(el) {
    if (!el) {
        return
    }

    const Obj = Object.create(AccordionList)
    initList.call(Obj, el)
    return Obj
}

function initList(el) {
    this.el = el
    this.closeOtherItems = this.el.dataset.independantItems !== 'true'
    const accordionsEls = this.el.querySelectorAll('.js-accordion')
    const accordions = Array.from(accordionsEls)
    this.items = accordions.map(el => createAccordion(el, this))
}

function createAccordion(el, parent) {
    if (!el) {
        return
    }

    const Obj = Object.create(Accordion)
    init.call(Obj, el, parent)
    return Obj
}

function init(el, parent) {
    this.el = el
    this.parent = parent
    this.btn = this.el.querySelector('.js-accordion-btn')
    this.container = this.el.querySelector('.js-accordion-container')

    if (typeof this.el.dataset.innerFocusOpen === 'string') {
        this.innerFocusOpen = this.el.dataset.innerFocusOpen === 'true'
    }

    if (this.el.classList.contains('is-active')) {
        this.container.style.height = Math.ceil(this.container.scrollHeight) + 'px'
    }

    this.focusableItems = this.container.querySelectorAll(
        'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'
    )
    this.focusableItems = Array.from(this.focusableItems)

    setupEvents.call(this)
}

function setupEvents() {
    this.btn.addEventListener('click', toggleAccordion.bind(this))

    this.container.addEventListener('transitionend', evt => {
        if (evt.target !== this.container) {
            return
        }

        if (this.isOpen) {
            this.container.style.height = 'auto'
        } else {
            if (!this.innerFocusOpen) {
                this.container.hidden = true
            }
            this.btn.setAttribute('aria-expanded', false)
        }
    })

    this.container.addEventListener('focusin', function() {
        if (!this.isOpen && this.innerFocusOpen) {
            openAccordion.call(this)
        }
    })

    this.container.addEventListener('focusout', function(evt) {
        if (!~this.focusableItems.indexOf(evt.relatedTarget) && this.innerFocusOpen) {
            closeAccordion.call(this)
        }
    })
}

function toggleAccordion() {
    if (this.isOpen) {
        closeAccordion.call(this)
    } else {
        openAccordion.call(this)
    }
}

function openAccordion() {
    if (this.animationFrame) {
        cancelAnimationFrame(this.animationFrame)
    }

    if (!this.innerFocusOpen) {
        this.container.hidden = false
    }

    this.container.style.height = Math.ceil(this.container.scrollHeight) + 'px'
    this.el.classList.add(classes.active)
    this.btn.setAttribute('aria-expanded', true)
    closeOtherAccordions.call(this)

    this.isOpen = true
}

function closeAccordion() {
    if (this.animationFrame) {
        cancelAnimationFrame(this.animationFrame)
    }

    this.container.style.height = Math.ceil(this.container.scrollHeight) + 'px'

    this.animationFrame = requestAnimationFrame(() => {
        this.el.classList.remove('is-active')
        this.container.style.height = 0
        this.animationFrame = null
    })

    this.isOpen = false
}

function closeOtherAccordions() {
    if (!this.parent.closeOtherItems) {
        return
    }

    this.parent.items.forEach(accordion => {
        if (accordion === this) {
            return
        }

        closeAccordion.call(accordion)
    })
}