// To initialise module in application.js
// import ObjectFit from './modules/object-fit'
// ;[...document.querySelectorAll(_('object-fit-cover', 'polyfill').str())].map(
//     (el) => new ObjectFit(el, 'cover')
// )
// ;[...document.querySelectorAll(_('object-fit-contain', 'polyfill').str())].map(
//     (el) => new ObjectFit(el, 'contain')
// )
//
// Markup:
//    <div data-polyfill="object-fit-cover"></div>
//    <div data-polyfill="object-fit-contain"></div>

export default class ObjectFit {
    constructor(el, type) {
        this.element = el
        this.type = type
        if (
            this.element &&
            (!window.CSS || !window.CSS.supports('object-fit', 'cover'))
        ) {
            this.objectFitPolyfill()
        }
    }

    objectFitPolyfill = () => {
        const imageSrc = this.element.getAttribute('src')
        const bgImageNode = document.createElement('div')
        const classNames = this.element.getAttribute('class')
        const classArray = classNames.split(' ')
        classArray.map(className => {
            bgImageNode.classList.add(className)
        })
        bgImageNode.style.backgroundImage = `url(${imageSrc})`
        bgImageNode.style.backgroundPosition = 'center'
        bgImageNode.style.backgroundSize = this.type
        this.element.parentNode.insertBefore(bgImageNode, this.element)
        this.element.remove()
    }
}
