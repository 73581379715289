const apiKey = 'AIzaSyCBkdHFnw0-UaA4rTak23A_BHhBETlmAjo'

const defaultIconImage = '/application/themes/rawnet/app/images/icons/new_map_marker.svg'
const hoverIconImage = '/application/themes/rawnet/app/images/icons/new_map_marker_hover.svg'
const selectedIconImage = '/application/themes/rawnet/app/images/icons/new_map_marker_selected.svg'
// const locationIcon = '/application/themes/rawnet/app/images/icons/new_map_marker_location.svg'

let el
let form
let resetBtn
let currentLocation
let resultsTitle
let resultsContainer
let mapContainer

let searchAction
let bounds
let map
let locations
let infoWindow
let div
let holder
let selectedItem
// let locationMarker
let defaultIcon
let hoverIcon
let selectedIcon

export function shopsMap() {
    el = document.querySelector('.js-shops-map')
    if (!el) {
        return
    }

    form = el.querySelector('.js-shops-map-search-form')
    searchAction = form.dataset.ajaxAction

    currentLocation = el.querySelector('.js-shops-map-current-location')
    resultsTitle = el.querySelector('.js-shops-map-title')
    resultsContainer = el.querySelector('.js-shops-map-item-container')
    mapContainer = el.querySelector('.js-shops-map-google-map')
    resetBtn = el.querySelector('.js-shops-map-reset')

    div = document.createElement('div')
    holder = document.createElement('div')

    loadMap()

    form.addEventListener('submit', geocodeSearch)
    currentLocation.addEventListener('click', getCurrentLocation)
    resetBtn.addEventListener('click', resetSearch)
}

function loadMap() {
    const gmapLoader = document.querySelector('#gmap_loader')

    if (gmapLoader) {
        initMap()
        return
    }

    const script = document.createElement('script')
    script.id = 'gmap_loader'
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initShopsMap`
    document.head.appendChild(script)
}

window.initShopsMap = initMap
function initMap() {
    infoWindow = new window.google.maps.InfoWindow()

    defaultIcon = {
        url: defaultIconImage,
        size: new window.google.maps.Size(37, 52),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(19, 52)
    }

    hoverIcon = {
        url: hoverIconImage,
        size: new window.google.maps.Size(37, 52),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(19, 52)
    }

    selectedIcon = {
        url: selectedIconImage,
        size: new window.google.maps.Size(37, 52),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(19, 52)
    }

    setupLocations()

    const options = {
        center: bounds.getCenter(),
        zoom: +mapContainer.dataset.zoom || 11,
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        mapTypeControl: false,
        scrollwheel: false
    }

    map = new window.google.maps.Map(mapContainer, options)

    if (locations.length > 0) {
        map.fitBounds(bounds)
    } else {
        map.setCenter({lat: 54.5, lng: -3.5})
    }

    // locationMarker = new window.google.maps.Marker({
    //     flat: true,
    //     icon: locationIcon
    // })

    addMarkers()
}

function resetLocations(ignoreLocations = false) {
    if (ignoreLocations) {
        setupLocations()
    }

    if (locations.length > 0) {
        map.fitBounds(bounds)
    } else {
        map.setCenter({lat: 54.5, lng: -3.5})
    }

    addMarkers()
}

function setupLocations() {
    let locationsEls = resultsContainer.querySelectorAll('[data-lat]')
    locationsEls = Array.from(locationsEls)

    bounds = new window.google.maps.LatLngBounds()

    locations = locationsEls.map((element, index) => {
        const {lat, lng} = element.dataset
        const position = new window.google.maps.LatLng(+lat, +lng)

        bounds.extend(position)

        const marker = new window.google.maps.Marker({
            position,
            flat: true,
            icon: defaultIcon
        })

        marker.addListener('mouseover', () => {
            if (!selectedItem || selectedItem.element !== element) {
                marker.setIcon(hoverIcon)
            }
        })

        marker.addListener('mouseout', () => {
            if (!selectedItem || selectedItem.element !== element) {
                marker.setIcon(defaultIcon)
            }
        })

        marker.addListener('click', () => {
            markerClicked(index)
        })

        element.addEventListener('mouseover', () => {
            if (!selectedItem || selectedItem.element !== element) {
                marker.setIcon(hoverIcon)
            }
        })

        element.addEventListener('mouseout', () => {
            if (!selectedItem || selectedItem.element !== element) {
                marker.setIcon(defaultIcon)
            }
        })

        return {
            element,
            lat,
            lng,
            position,
            distance: element.querySelector('.js-shops-map-distance'),
            distanceNum: 0,
            content: element.innerHTML,
            marker,
            originalIndex: index,
            directionLink: element.querySelector('.js-shops-map-item-directions')
        }
    })
}

function markerClicked(index) {
    updateSelection(index)
    selectedItem.element.scrollIntoView({behavior: 'smooth', block: 'nearest'})
}

function itemClicked(index) {
    updateSelection(index)

    map.panTo(selectedItem.marker.getPosition())
}

function updateSelection(index) {
    const newSelectItem = locations.find((e) => e.originalIndex === index)

    if (selectedItem) {
        selectedItem.marker.setIcon(defaultIcon)
        selectedItem.element.classList.remove('selected')
    }

    selectedItem = newSelectItem
    newSelectItem.marker.setIcon(selectedIcon)
    newSelectItem.element.classList.add('selected')
}

function addMarkers() {
    locations.forEach((element) => {
        element.marker.setMap(map)

        window.google.maps.event.addListener(element.marker, 'click', () => {
            infoWindow.setContent(element.content)
            infoWindow.open(map, element.marker)
        })

        element.element.addEventListener('click', () => {
            itemClicked(element.originalIndex)
        })
    })
}

function search(evt) {
    if (evt) {
        evt.preventDefault()
    }

    const data = new FormData(form)
    const loc = data.get('loc')

    fetch(searchAction, {
        method: 'POST',
        body: data
    }).then(res => res.text())
        .then(html => {
            if (html === 'false') {
                resultsTitle.innerText = ''
                return
            }

            locations.forEach(element => {
                element.element.remove()
            })

            div.innerHTML = html

            Array.from(div.children).forEach(item => {
                resultsContainer.appendChild(item)
            })

            clearDistance()
            resetLocations()
            resultsTitle.innerText = loc
        })
}

function geocodeSearch(evt) {
    if (evt) {
        evt.preventDefault()
    }

    const data = new FormData(form)
    let loc = data.get('loc')
    const locLabel = loc

    if (!loc.includes('uk')) {
        loc += ' uk'
    }

    currentLocation.classList.remove('active')

    fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(loc)}&key=${apiKey}`)
        .then(res => res.json())
        .then(result => {
            const {lat, lng} = result.results[0].geometry.location

            currentLocationSearch({
                coords: {
                    latitude: lat,
                    longitude: lng
                }
            }, locLabel)
        })
}

function getCurrentLocation(evt) {
    evt.preventDefault()

    function error() {
        resultsTitle.innerText = 'failed to grab your location'
    }

    resultsTitle.classList.add('loading')

    navigator.geolocation.getCurrentPosition((pos) => {
        currentLocationSearch(pos)
        currentLocation.classList.add('active')
    }, error, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
    })
}

function currentLocationSearch(pos, locationText = 'current location') {
    const {latitude, longitude} = pos.coords
    resultsTitle.innerText = locationText
    resultsTitle.classList.remove('loading')

    // locationMarker.setPosition(new window.google.maps.LatLng(latitude, longitude))
    // locationMarker.setMap(map)

    setdirectionLinks(latitude, longitude)

    for (let index = 0; index < locations.length; index++) {
        const element = locations[index]
        const distance = distanceCalc(latitude, longitude, element.lat, element.lng)
        const miles = distance * 0.621371
        let label = Math.round(miles)
        if (label === 0) {
            label = miles.toFixed(2)
        }

        element.distance.innerText = label + 'm'
        element.distanceNum = distance
    }

    locations.sort(function(a,b) {
        return a.distanceNum - b.distanceNum
    })

    locations.forEach(element => {
        holder.appendChild(element.element)
    })

    locations.forEach(element => {
        resultsContainer.appendChild(element.element)
    })

    locations[0].element.scrollIntoView({behavior: 'smooth', block: 'nearest'})

    itemClicked(locations[0].originalIndex)
    resetLocations(false)
}

function clearDistance() {
    locations.forEach(element => {
        element.distance.innerText = ''
        element.distanceNum = 0
    })
}

function setdirectionLinks(lat, lng) {
    locations.forEach((loc) => {
        loc.directionLink.href = googleDirectionsLink(loc.lat, loc.lng, lat, lng)
    })
}

function resetSearch(evt) {
    evt.preventDefault()

    setdirectionLinks()
    clearDistance()
    currentLocation.classList.remove('active')
    resultsTitle.innerText = ''

    // locationMarker.setMap(null)

    locations.sort(function(a,b) {
        return a.originalIndex - b.originalIndex
    })

    locations.forEach(element => {
        holder.appendChild(element.element)
    })

    locations.forEach(element => {
        resultsContainer.appendChild(element.element)
    })

    locations[0].element.scrollIntoView({behavior: 'smooth', block: 'nearest'})
}

function distanceCalc(lat1, lon1, lat2, lon2) {
    const R = 6371 // km
    const dLat = toRad(lat2 - lat1)
    const dLon = toRad(lon2 - lon1)
    lat1 = toRad(lat1)
    lat2 = toRad(lat2)

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    const d = R * c
    return d
}

// Converts numeric degrees to radians
function toRad(Value) {
    return Value * Math.PI / 180
}

function googleDirectionsLink(lat1, lng1, lat2, lng2) {
    if (!lat2) {
        return `https://www.google.co.uk/maps/dir/${lat1},${lng1}/`
    }

    return `https://www.google.co.uk/maps/dir/${lat1},${lng1}/${lat2},${lng2}`
}
