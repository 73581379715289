import { _ } from '../utils'
import PostcodeLookup from './postcode-lookup'

export default class Accordion {
    constructor(el) {
        this.element = $(el)
        this.trigger = this.element.find(_('accordion-trigger').el())
        this.content = $(_('accordion-content', 'element'))
        this.allContent = this.element.find(_('accordion-content', 'element').el())
        this.activeTriggerClass = 'accordion__link--active'

        this.trigger.off('click').on('click', this.toggleAccordion)
        if (new URLSearchParams() !== undefined) {
            if (window.location.href.indexOf('participant') > -1) {
                const participantParams = new URLSearchParams(window.location.search);
                const participant = participantParams.get('participant');
                _(participant, 'participant').el().find(_('accordion-trigger').el()).click()
            }
        }
    }

    toggleAccordion = (e) => {
        e.preventDefault()
        const trigger = $(e.currentTarget)
        const target = $(trigger).siblings(this.content)
        const isOpen = target.is(':visible')

        this.allContent.slideUp('fast')
        this.trigger.removeClass(this.activeTriggerClass)

        if (isOpen) {
            target.slideUp('fast')
        } else {
            target.slideDown('fast')
        }

        trigger.toggleClass(this.activeTriggerClass, !isOpen)
        _('postcode-lookup').el().map((i, el) => new PostcodeLookup(el, i))
    }
}
