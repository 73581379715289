const template = (data) => `
<div class="grid__item">
    <a href="${data.url}" class="card${data.featured ? ` card--event-featured` : ``}">
        <article class="card__article">
            <div class="card__media-container">         
                ${data.picture ? data.picture : (data.image ? `<img class="card__media u-object-fit-cover" src="${data.image}" alt="${data.title} image" data-polyfill="object-fit-cover" />` : '')}
            </div>
            <div class="card__content">
                ${data.type ? `
                    <div class="card__tags">
                        <div class="tag tag--${data.type.handle}">${data.type.name}</div>
                    </div>
                ` : ''}
                <h2 class="card__heading heading--three">${data.title}</h2>
                ${data.abstract ? `
                    <p class="card__copy">${data.abstract}</p>
                ` : ``}
                <div class="card__meta">
                    ${data.date ? `
                        <p class="card__date">${data.date}</p>
                    ` : ``}
                    ${data.location ? `
                        <p class="card__copy">${data.location}</p>
                    `: ``}
                    ${data.startDate ? `
                        <div class="event-summary">
                            <svg width="14" height="14" class="event-summary__icon">
                                <use xlink:href="/application/themes/rawnet/app/images/sprite.svg#calendar"></use>
                            </svg>
                            ${data.startDate} ${data.endDate &&  ` - ${data.endDate}`}
                        </div>
                    ` : ``}
                    ${data.venue ? `
                        <div class="event-summary">
                            <svg width="14" height="14" class="event-summary__icon">
                                <use xlink:href="/application/themes/rawnet/app/images/sprite.svg#location"></use>
                            </svg>
                            ${data.venue}
                        </div>
                    ` : ``}
                </div>
            </div>
        </article>
    </a>
</div>
`
export default template
