import { _ } from '../utils'

export default class Modal {
    constructor(el) {
        this.el = $(el)

        this.el.on('click', this.toggleModal)
    }

    toggleModal = (e) => {
        const target = $(e.currentTarget).data('target')
        $('body').toggleClass('u-no-scroll')
        $('[data-element="' + target + '"]').toggleClass('modal--open')

    }
}
