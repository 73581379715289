const template = (data, label) => (
    `<div class="radio-toggle">
        ${data.map((option, i) => `
            <div class="radio-toggle__button ${option.selected ? `radio-toggle__button--active` : ''}">
                <input data-behaviour="filter" type="radio" name="filter[is_volunteer_job]" data-id="${i}" id="jobs${i}" name="group" class="sr-only" />
                <label class="radio-toggle__label" for="jobs${i}">
                    ${option.name}
                </label>
            </div>
        `).join('')}
    </div>`
)

export default template
