export const debug = true
class Selector {
    constructor(selector, attribute = 'behaviour') {
        this.main = (thunk) => thunk(attribute, selector)
    }
    str = () =>
        this.main((attribute, selector) => `[data-${attribute}="${selector}"]`)
    el = () => $(this.str())
    node = () => document.querySelector(this.str())
    nodeFrom = (el) => el.querySelector(this.str())
    nodes = () => [...document.querySelectorAll(this.str())]
    nodesFrom = (el) => [...el.querySelectorAll(this.str())]
}

export const _ = (selector, attribute = 'behaviour') => {
    return new Selector(selector, attribute)
}

/**
 *
 * @param {array} els - node array
 * @param {string} className - class name to toggle
 *
 */

export const toggleClasses = (els, className) => {
    els.map((el) => {
        el.classList.toggle(className)
    })
}

/**
 *
 * @param {array} els - node array
 * @param {string} className - class name to add
 *
 */

export const addClasses = (els, className) => {
    els.map((el) => {
        el.classList.add(className)
    })
}

/**
 *
 * @param {array} els - node array
 * @param {string} className - class name to add
 *
 */

export const removeClasses = (els, className) => {
    els.map((el) => {
        el.classList.remove(className)
    })
}
/**
 *
 * @param {object} parent - the closest parent you're trying to find
 * @param {object} child - the child you're starting with
 *
 */

export const closestEl = (parent, child) => {
    if (!parent || !parent.dataset || !child || !child.dataset) {
        return false
    }

    while (
        child.dataset.element !== parent.dataset.element ||
        child.dataset.behaviour !== parent.dataset.behaviour
    ) {
        child = child.parentNode
        if (child === document) {
            return null
        }
    }
    return child
}

//  can be used as such:
// _('toggle').el().on('input', debounce(this.func, 500))
// _('toggle').node().addEventListener('keyup', debounce(this.search, 500))
export const debounce = (callback, wait) => {
    let timeout
    return (...args) => {
        const context = this
        clearTimeout(timeout)
        timeout = setTimeout(() => callback.apply(context, args), wait)
    }
}

/* global dataLayer */

/**
 *
 * @param {string} trigger - element which holds the extra data attributes
 *
 */

// Can be used with makeGtmCall(el)
// Either add as data attributes in the markup el has:
//  - <a href="#" data-gtm-event="pdf-download" data-gtm-name="example.pdf"> download </a>
// or adding in the js before calling makeGtmCall(el)
//  - el.dataset.gtmEvent = 'pdf-download'; el.dataset.gtmName = 'example.pdf'
// every data attribute with "gtm" will go in the object

export const makeGtmCall = (trigger) => {
    const object = {}

    const keys = Object.keys(trigger.dataset)
    for (const key of keys) {
        if (key.includes('gtm')) {
            const cleanKey = key.replace('gtm', '')
            const formattedKey =
                cleanKey.charAt(0).toLowerCase() + cleanKey.slice(1)

            object[formattedKey] = trigger.dataset[key]
        }
    }

    if (debug) {
        console.log(object)
    }
    if (
        typeof dataLayer === 'undefined' ||
        typeof trigger.dataset.gtmEvent === 'undefined'
    ) {
        return false
    }

    dataLayer.push(object)
}
