import {_} from '../utils'
import axios from 'axios'
import Loading from '../templates/loading'
import errorMessageContainer from '../templates/error'
import pagination from '../templates/pagination'
import card from '../templates/card'
import cta from '../templates/cta'
import filter from '../templates/filter'
import filterButtons from '../templates/filter-buttons'
import filterToggle from '../templates/filter-toggle'
import Slider from './slider'
import ObjectFit from './object-fit'

export default class Listing {
    constructor(el) {
        this.el = el
        this.form = _('form', 'element').nodeFrom(this.el)
        this.cta = this.form.getAttribute('data-cta')
        this.filter = _('filter', 'element').nodeFrom(this.el)
        this.apiUrl = this.form.getAttribute('action')
        this.pageId = this.el.getAttribute('data-page-id')
        this.defaultErrorMessage =
            'Something has gone quite wrong, please try to refresh your browser or contact us.'
        this.pagination = _('pagination', 'element').nodeFrom(this.el)
        this.paginator = _('pagination').nodeFrom(this.el)
        this.action = this.el.dataset.action || 'get'
        this.resultsContainer = _('results-container', 'element').nodeFrom(
            this.el
        )
        this.filterContainer = _('filters', 'element').nodeFrom(this.el)
        this.noResultsContainer = _('no-results', 'element').nodeFrom(this.el)
        this.page = 1

        this.currentFilter = {}

        this.search = _('search', 'element').nodeFrom(this.el)

        this.setSearch()

        this.form.addEventListener('submit', this.formSubmit)
        this.getResults()

    }

    incrementPage = () => {
        this.page++
        this.getResults()
    }

    decrementPage = () => {
        this.page--
        this.getResults()
    }

    selectChange = (e) => {
        let {target: { options: { selectedIndex } }} = e
        this.page = selectedIndex++
        this.getResults()
    }

    filterChange = (e) => {
        if (e.currentTarget.nodeName === 'SELECT') {
            const selectedIndex =  e.currentTarget.options.selectedIndex
            const targetId = e.currentTarget.options[selectedIndex].id
            this.currentFilter[e.currentTarget.name] = targetId
        } else {
            this.currentFilter[e.currentTarget.name] = e.currentTarget.dataset.id
        }
        this.page = 1
        this.getResults()
    }

    formSubmit = (e) => {
        e.preventDefault()

        if (this.search) {
            this.page = 1
            this.setSearch()
            this.getResults()
        }
    }

    setSearch = () => {
        if (this.search) {
            this.currentFilter[this.search.name] = this.search.value
        }
    }

    showLoading = () => {
        this.noResultsContainer.classList.add('u-hide')
        this.resultsContainer.innerHTML =
            '<div class="loading" data-behaviour="loading"></div>'
        _('loading')
            .nodes()
            .map((el) => new Loading(el))
    }

    hideLoading = () => {
        this.resultsContainer.innerHTML = ''
    }

    getResults = (scrollTop = false, append = false) => {
        if (!append) {
            this.showLoading()
        }

        const params = {
            pageId: this.pageId,
            page: this.page
        }

        if (this.filter || this.search) {
            const params2 = Object.assign(params, this.currentFilter);
        }

        if (window.location.hash === '#volunteer' && params['filter[is_volunteer_job]'] !== '0') {
            params['filter[is_volunteer_job]'] = 1
        }

        axios.get(this.apiUrl, {
            data: {},
            params: params,
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            params['filter[is_volunteer_job]'] = 0

            if (response.status !== 200) {
                return this.handleError(response)
            }

            if (response.data.results.length === 0) {
                this.handleNoResults()
            }

            if (this.pagination) {
                this.buildPagination(response.data)
            }

            if (response.data.categories) {
                this.buildCategories(response.data.categories, response.data.categoriesDisplay)
            }
            this.handleSuccess(response.data, append)
            if (scrollTop) {
                this.scrollToResultsTop()
            }
        }).catch((error) => {
            this.handleError(error)
        })
    }

    scrollToResultsTop = () => {
        window.scrollTo({
            top:
                this.resultsContainer.getBoundingClientRect().top -
                document.body.getBoundingClientRect().top -
                30,
            behavior: 'smooth'
        })
    }

    buildCategories = (category, display) => {
        let item = ''
        Object.keys(category).forEach(function(key) {
            if (category.news_category) {
                item += filterButtons(category[key], key)
            } else if (category.is_volunteer_job) {
                item += filterToggle(category[key], key)
            } else {
                item += filter(category[key], display[key], key)
            }
        })

        this.filterContainer.innerHTML = item
        this.filter = _('filter').nodesFrom(this.el)
        this.filter.map(el => el.addEventListener('change', this.filterChange))
    }

    buildPagination = (response) => {
        if (
            !response.results.length ||
            (response.pagination &&
                response.pagination.totalPages !== null &&
                response.pagination.totalPages < 2)
        ) {
            this.pagination.classList.add('u-hidden')
            return false
        } else {
            this.pagination.classList.remove('u-hidden')
        }

        this.pagination.innerHTML = `<button class="button button--wide" type="button" data-behaviour="load-more">Load More</button>`

        this.loadmore = _('load-more').nodeFrom(this.el)
        this.loadmore.addEventListener('click', () => {
            this.page++
            this.getResults(false, true)
        })
        if (response.pagination.totalPages === response.pagination.page) {
            $('[data-behaviour="load-more"]').addClass('u-hidden')
        }
    }

    handleSuccess = (response, append) => {
        if (!append) {
            this.hideLoading()
        }
        this.buildCard(response, append)
        _('slider').el().map((i, el) => new Slider(el, i))
        _('object-fit-cover', 'polyfill').nodes().map((el) => new ObjectFit(el, 'cover'))
        _('object-fit-contain', 'polyfill').nodes().map((el) => new ObjectFit(el, 'contain'))
    }

    buildCard = (response, append) => {
        let resultsHTML = ''

        if (response.results) {

            if (response.html) {
                resultsHTML = response.html
            } else {
                resultsHTML +=
                    '<div class="grid grid--3-col grid--row-gap-large grid--break@large">'
                response.results.map((el, i) => {
                    const item = card(response.results[i])
                    resultsHTML += item

                    if (i === parseFloat(this.cta) && response.cards) {
                        resultsHTML += cta(response.cards[0])
                    }
                    if (!this.cta && i === 7 && response.cards) {
                        resultsHTML += cta(response.cards[0])
                    }
                })
                resultsHTML += '</div>'
            }

        }
        if (append) {
            const newNode = document.createElement("span")
            newNode.innerHTML = resultsHTML
            this.resultsContainer.parentNode.querySelector('[data-element="results-container"]').appendChild(newNode)
        } else {
            this.resultsContainer.innerHTML = resultsHTML
        }
    }

    handleNoResults = () => {
        this.noResultsContainer.classList.remove('u-hide')
        this.hideLoading()
    }

    handleError = (err, defaultMessage = this.defaultErrorMessage) => {
        console.warn(err)

        this.hideLoading()
        let errorHTML = `<p>${defaultMessage}</p>`
        if (err && err.errors) {
            err.errors.map((el) => {
                errorHTML += `<p>${el}</p>`
            })
        }
        this.resultsContainer.innerHTML = errorMessageContainer(errorHTML)
    }
}
