import {_} from '../utils'

export default class Video {
    constructor(el) {
        this.element = $(el)
        this.element.on('click', this.loadVideo);
    }

    loadVideo = () => {
        if (this.element.find("iframe").length) {
            const symbol = this.element.find("iframe")[0].src.indexOf("?") > -1 ? "&" : "?";
            this.element.find("iframe")[0].src += symbol + "autoplay=1&mute=1";
        } else {
            this.element.find("video")[0].play();
        }
        this.element.find(".video__poster").addClass("video__poster--hide");
    }
}
