import { _, removeClasses, closestEl } from '../utils'

export default class Tabs {
    constructor(el) {
        this.el = el
        this.tabIndex = 1
        this.tabTrigger = _('tab-trigger', 'element').nodesFrom(this.el)
        this.class = {
            button: 'tabs__link--active steps__item--active',
            content: 'tab-content__item--active'
        }

        Array.from(this.tabTrigger).forEach(tabLink => {
            tabLink.addEventListener('click', this.toggleTab)
        })

        if (window.location.hash) {
            Array.from(this.tabTrigger).forEach(tabLink => {
                if (tabLink.hash === window.location.hash) {
                    tabLink.click()
                }
            })
        }
    }

    toggleTab = (e) => {
        e.preventDefault()
        if (e.target.getAttribute('data-element') === 'tab-select') {
            return false;
        }
        const currentTab = e.target
        const tabsContainer = closestEl(_('tabs').node(), e.target)
        const activeTabClass = tabsContainer.querySelector(
            _('tab-trigger', 'element').str()
        ).dataset.class
        const select = _('tab-select', 'element').nodeFrom(tabsContainer)

        const isTabOpen = currentTab.classList.contains(activeTabClass)
        if (isTabOpen) {
            return false
        }
        const tabTarget = currentTab.getAttribute('href')
        const tabs = [
            ...tabsContainer.querySelectorAll(_('tab-trigger', 'element').str())
        ]
        const tabContents = _('tab-container', 'element').nodesFrom(tabsContainer)
        const activeTabContentClass = tabsContainer.querySelector(
            _('tab-container', 'element').str()
        ).dataset.class

        removeClasses(tabs, activeTabClass)
        removeClasses(tabContents, activeTabContentClass)

        currentTab.classList.add(activeTabClass)

        select.innerHTML = e.target.innerText
        console.log(document.querySelector(tabTarget));
        document.querySelector(tabTarget).classList.add(activeTabContentClass)
    }
}
