import { _ } from '../utils'

class Toggle {
    constructor () {
        $(document).on('click', _('toggle-class').str(), this.toggleClass)
    }

    toggleClass = (e) => {
        const trigger = $(e.currentTarget)
        const target = trigger.data('target')
        const targetClass = _(target, 'element').el().data('class')

        _(target, 'element').el().toggleClass(targetClass)

        if (trigger.data('toggle') === 'child-address') {
            const childContainer = trigger.parents('[data-element="participantChild"]').find('[data-element="address-group"]')
            childContainer.find('[data-element="postcode-input"]').prop('required', !childContainer.find('[data-element="postcode-input"]').prop('required'))
            childContainer.find('[data-element="address1"]').prop('required', !childContainer.find('[data-element="address1"]').prop('required'))
            childContainer.find('[data-element="address2"]').prop('required', !childContainer.find('[data-element="address2"]').prop('required'))
            childContainer.find('[data-element="city"]').prop('required', !childContainer.find('[data-element="city"]').prop('required'))
            childContainer.find('[data-element="county"]').prop('required', !childContainer.find('[data-element="county"]').prop('required'))
        }
    }
}

export default new Toggle
