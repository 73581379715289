import { _ } from '../utils'
export default class PostcodeLookup2 {

    constructor(el) {
        this.element = $(el)
        this.participant = $('[data-element="manageParticipant"]')
        this.postcodeInput = this.element.closest(this.participant).find('[data-element="postcode-input"]')
        this.areaSelection = this.element.closest(this.participant).find('[data-element="area-selection"]')
        this.addressSelection = this.element.closest(this.participant).find('[data-element="address-selection"]')
        this.areaSelection = this.element.closest(this.participant).find('[data-behaviour="area-selected"]')
        this.address = this.element.closest(this.participant).find('[data-element="address"]')

        this.address1 = this.element.closest(this.participant).find('[data-element="address1"]')
        this.address2 = this.element.closest(this.participant).find('[data-element="address2"]')
        this.city = this.element.closest(this.participant).find('[data-element="city"]')
        this.county = this.element.closest(this.participant).find('[data-element="county"]')

        this.element.on('click', this.postcodeLookup)
        this.areaSelection.on('change', this.areaSelected)

        if (this.address1.val() !== '') {
            this.address.show()
        }
    }

    postcodeLookup = (e) => {
        const self = this
        $.ajax({
            url: '/api/postcode_lookup/' + self.postcodeInput.val().replace(/ /g,''),
            headers: {
                'x-api-key': _('csrf', 'element').el().attr('content')
            },
        }).done(function(response) {
            $(self.areaSelection).closest('.u-hide').removeClass('u-hide')
            let options = '<option disabled selected hidden>Select Address</option>'

            Object.keys(response.addresses).forEach(function(key) {
                options += `<option data-id="${response.addresses[key].Id}">${response.addresses[key].Text ? response.addresses[key].Text : response.addresses[key].Line1 + ', ' + response.addresses[key].Line2}</option>`
            });
            $('[data-element="area-selection"]').html(options)
        });
    }

    areaSelected = (e) => {
        const self = this
        const area = $(e.currentTarget).find("option:selected").data('id')
        $.ajax({
            url: '/api/postcode_lookup/address/' + area,
            headers: {
                'x-api-key': _('csrf', 'element').el().attr('content')
            },
        }).done(function(response) {
            self.address.show()
            self.address1.val(response.address.Line1)
            self.address2.val(response.address.Line2)
            self.city.val(response.address.City)
            self.county.val(response.address.Province)
        });
    }
}
