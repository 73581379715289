import { _ } from '../utils'

export default class InputUpload {
    constructor(el) {
        this.el = $(el)

        this.info = this.el.closest('.input-upload').find('[data-element="input-information"]')

        this.name = this.info.find('[data-element="input-upload-name"]')
        this.size = this.info.find('[data-element="input-upload-size"]')
        this.remove = this.info.find('[data-behaviour="input-upload-remove"]')

        this.el.on('change', this.getFileInfo)
        this.remove.on('click', this.removeFile)
    }

    getFileInfo = (e) => {
        const files = e.currentTarget.files[0].name
        const fileSize = (e.currentTarget.files[0].size / 1000).toFixed(1) + 'kb'

        this.name.html(files)
        this.size.html(fileSize)

        this.info.addClass('input-upload__information--active')
    }

    removeFile = () => {
        this.el.value = ''

        this.name.html('')
        this.size.html('')

        this.info.removeClass('input-upload__information--active')
    }
}
