import { _ } from '../utils'
import 'slick-carousel'

export default class Slider {

    constructor(el) {
        if(el.classList.contains('js-events-slider')) {
            this.eventSlider = $(el)
            this.element = $(el.firstElementChild)
            this.prevArrow = this.eventSlider.find('.js-prev-button')
            this.nextArrow = this.eventSlider.find('.js-next-button')
            this.pos = this.eventSlider.find('.js-pos')
        } else {
            this.element = $(el)
        }

        this.slides = this.element.data('slides')
        this.dots = this.element.data('dots')
        this.infinite = this.element.data('infinite')
        this.arrows = this.element.data('arrows')
        this.autoplay = this.element.data('autoplay')

        this.slideCount = this.element.find(_('slide', 'element').str()).length
        this.dragging = false

        const self = this

        this.element.on('init', (event, slick) => {
            self.slider(event, slick)
        })
        this.element.on('afterChange', this.slider.bind(this))
        this.buildSlider()

        this.breakpoint = window.matchMedia(`(max-width: 768px)`)
        this.breakpoint.addListener(this.buildSlider)

        if ( !window.IntersectionObserver ) {
            this.buildSlider()
        } else {
            this.lazyInit()
        }

        this.element.on('beforeChange', () => {
            this.dragging = true
        })

        this.element.on('afterChange', () => {
            this.dragging = false
        })
    }

    buildSlider = () => {
        const defaults = {
            dots: this.dots ? this.dots : false,
            arrows: this.arrows ? this.arrows: false,
            infinite: this.infinite ? this.infinite : false,
            autoplay: this.autoplay ? true : false,
            autoplaySpeed: 5000,
            slidesToScroll: 1,
            slidesToShow: this.slides ? this.slides : 1,
            responsive: [{
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }],
        }

        const setting = []

        setting.prevArrow =`
            <button class="slick-prev">
                <svg width="20" height="20" class="slider__icon">
                    <use xlink:href="/application/themes/rawnet/app/images/sprite.svg#arrow"></use>
                </svg>
                <span class="sr-only">previous slider</span>
            </button>`

        setting.nextArrow = `
            <button class="slick-next">
                <svg width="20" height="20" class="slider__icon slider__icon--reverse">
                    <use xlink:href="/application/themes/rawnet/app/images/sprite.svg#arrow"></use>
                </svg>
                <span class="sr-only">next slider</span>
            </button>`

        if (this.autoplay) {
            setting.autoplaySpeed = 5000
        }

        const settings = $.extend(defaults, setting)
        $(this.element).not('.slick-initialized').slick(settings)

        if (this.eventSlider && this.eventSlider.length) {
            this.prevArrow.on('click', ()=>{
                $(this.element).slick('slickPrev')
            })

            this.nextArrow.on('click', ()=>{
                $(this.element).slick('slickNext')
            })

            this.pos.html(`${this.element[0].slick.currentSlide + 1}/${this.element[0].slick.slideCount}`)
        }
    }

    lazyInit = () => {
        const thisDefault = this
        const observer = new IntersectionObserver(function(entries, self) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    thisDefault.buildSlider(entry.target)
                    self.unobserve(entry.target)
                }
            })
        }, this.intersectionConfig)
        observer.observe(this.element[0])
    }

    slider = (event, slick) => {
        this.next = this.element.find('.slick-next')
        this.prev = this.element.find('.slick-prev')

        this.prev.hide()
        this.next.hide()

        this.prev.toggle(slick.currentSlide >= 1)
        this.next.toggle(slick.currentSlide < slick.slideCount - slick.options.slidesToShow)

        if (this.eventSlider && this.eventSlider.length) {
            this.pos.html(`${slick.currentSlide + 1}/${slick.slideCount}`)
        }
    }
}
