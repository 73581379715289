// To use in a project:
// Initialise module in application.js
// import LazyLoad from './modules/lazy-load'
// _('image', 'lazyload').el().map((i, el) => new LazyLoad(el))
// Add [data-src] to <source> or <img> with real image url
// And add src="/application/themes/rawnet/app/images/interface/base.svg"

export default class LazyLoad {
    constructor(el) {
        this.el = el
        this.config = {
            rootMargin: '300px',
            threshold: 0
        }

        if (
            !window.IntersectionObserver ||
            'loading' in HTMLImageElement.prototype ||
            (document.querySelector('.page') &&
                document
                    .querySelector('.page')
                    .classList.contains('page-edit-mode'))
        ) {
            this.swapSrc(this.el)
            return false
        }
        this.lazySwap()
    }

    lazySwap = () => {
        const observer = new IntersectionObserver((entries, self) => {
            const entry = entries[0]
            if (entry.isIntersecting) {
                this.swapSrc(entry.target)
                self.unobserve(entry.target)
            }
        }, this.config)

        observer.observe(this.el)
    }

    swapSrc = (el = this.el) => {
        const imageSrc = el.dataset.src
        el.setAttribute('src', imageSrc)

        if (el.parentNode.nodeName === 'PICTURE') {
            const sourceEl = el.parentNode.querySelectorAll('source');
            [...sourceEl].map((source) => {
                source.setAttribute('srcset', source.dataset.src)
            })
        }
    }
}
