import { _ } from '../utils'
export default class Carousel {

    constructor(el) {
        this.element = $(el)
        this.dots = this.element.data('dots')
        this.arrows = this.element.data('arrows')
        this.slides = this.element.data('slides')

        this.element.slick({
            slidesToShow: this.slides || 4,
            dots: this.dots || false,
            arrows: this.arrows || false,
            draggable: this.element[0].draggable ? true : this.element[0].draggable,
            prevArrow: `
                <button class="slick-prev">
                    <svg width="20" height="20" class="card-carousel__icon">
                        <use xlink:href="/application/themes/rawnet/app/images/sprite.svg#arrow"></use>
                    </svg>
                    <span class="sr-only">previous slide</span>
                </button>`,
            nextArrow: `
                <button class="slick-next">
                    <svg width="20" height="20" class="card-carousel__icon card-carousel__icon--reverse">
                        <use xlink:href="/application/themes/rawnet/app/images/sprite.svg#arrow"></use>
                    </svg>
                    <span class="sr-only">next slide</span>
                </button>`,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        infinite: true,
                        centreMode: true,
                        draggable: true
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        infinite: true,
                        centreMode: true,
                        draggable: true
                    }
                }
            ]
        });
    }
}
