import { _ } from '../utils'

export default class Form {
    constructor(el) {
        this.form = $(el)
        this.url = this.form.attr('action')
        this.method = this.form.attr('method')
        this.feedback = this.form.find(_('feedback', 'element').el())

        this.form.on('submit', this.formSubmit)
    }

    formSubmit = (e) => {
        e.preventDefault()

        const formData = new FormData(this.form[0])

        $.ajax({
            url: this.url,
            method: this.method,
            headers: {
                'x-api-key': _('csrf', 'element').el().attr('content')
            },
            processData: false,
            contentType: false,
            data: formData,
            processData: false,
            success: (response) => {
                this.handleSuccess(response)
            },
            error: (err) => {
                this.handleError(err)
            }
        })
    }

    handleSuccess = (response) => {
        this.feedback[0].classList.add('u-success')
        this.feedback[0].innerHTML = response.success
        this.form[0].reset()
    }

    handleError = (response) => {
        this.feedback[0].classList.add('u-error')
        let errors = ''

        Object.keys(response.error).forEach(function (item) {
            errors += response.error[item]
        })

        this.feedback[0].innerHTML = errors
    }
}
