import {_} from '../utils'

export default class NavToggle {
    constructor(el) {
        this.el = $(el)
        this.target = this.el.data('target')
        this.el.on('click', this.toggleNav)
    }

    toggleNav = (e) => {
        this.el.toggleClass('nav-icon--active')
        _(this.target, 'element').el().toggleClass('header__nav--active')
        $('body').toggleClass('u-noscroll')
        $('.header').toggleClass('header--nav-active')
    }
}
