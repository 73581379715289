import { _ } from '../utils'
export default class Reminders {
    constructor() {
        _('text-reminder').el().on('click', function(e) {
            e.preventDefault()
            const participantId = $(e.currentTarget).data('participant')
            const type = $(e.currentTarget).data('type')

            $.ajax({
                url: '/api/events/reminder/sms/' + participantId,
                method: 'POST',
                data: {
                    contactNumber: $(e.currentTarget).siblings('[data-input="phone"]').val()
                },
                headers: {
                    'x-api-key': _('csrf', 'element').el().attr('content')
                },
            }).success(function(response) {
                $(e.currentTarget).parents('.form-group').find('[data-element="'+type+'"]').removeClass('messages__content--success')
                $(e.currentTarget).parents('.form-group').find('[data-element="'+type+'"]').addClass('messages__content--error')
                $(e.currentTarget).parents('.form-group').find('[data-element="'+type+'"]').html(response.Message)
            }).error(function(response) {
                $(e.currentTarget).parents('.form-group').find('[data-element="'+type+'"]').addClass('messages__content--error')
                $(e.currentTarget).parents('.form-group').find('[data-element="'+type+'"]').html(response.responseJSON.error)
            });
        })

        _('email-reminder').el().on('click', function(e) {
            e.preventDefault()
            const participantId = $(e.currentTarget).data('participant')
            const type = $(e.currentTarget).data('type')
            $.ajax({
                url: '/api/events/reminder/email/' + participantId,
                method: 'POST',
                data: participantId,
                headers: {
                    'x-api-key': _('csrf', 'element').el().attr('content')
                },
            }).success(function(response) {
                $(e.currentTarget).parents('.form-group').find('[data-element="'+type+'"]').html(response.Message)
            })
        })
    }
}
