/* global dataLayer */

import { _ } from '../utils'

export default class GtmCall {
    constructor(el) {
        this.el = el
        el.addEventListener('click', this.makeGtmCall)
    }

    makeGtmCall = (e) => {
        if (typeof dataLayer === 'undefined') {
            return false
        }
        const trigger = e.currentTarget
        const object = {
            'event': trigger.dataset.gtmEvent
        }
        if (trigger.dataset.gtmFile === 'true') {
            object.fileName = trigger.dataset.fileName
            object.fileTame = trigger.dataset.fileTame
        }
        dataLayer.push(object)
    }
}
