export default class Loading {
    constructor(el) {
        el.innerHTML = `
        <div class="loading-ring">
            <div class="loading-ring__element"></div>
            <div class="loading-ring__element"></div>
            <div class="loading-ring__element"></div>
            <div class="loading-ring__element"></div>
        </div>`
    }
}
