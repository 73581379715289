import { _ } from '../utils'

export default class ParticipantToggle {
    constructor (el) {
        this.el = $(el)
        this.participantToggle = _('participant-toggle').el() // All toggle buttons
        this.selectionInput = _('selection-type', 'element').el()
        this.selectionType = parseFloat(_('selection-type', 'element').el().val())

        if (this.selectionType !== 0) {
            this.showAll()
        }

        _('1', 'element').el().hide()
        _('2', 'element').el().hide()
        this.el.on('click', (e) => {
            this.toggleClass(e)
            this.showAll()
            this.participantToggle.hide()
            _('add-participant').el().show()
        })
    }

    showAll = () => {
        _('participant-show-all', 'element').el().show()
    }

    toggleClass = (e) => {
        const trigger = $(e.currentTarget)
        const targetName = trigger.data('target')
        const target = _(targetName, 'element').el()
        target.show()
        if ($(e.currentTarget).data('all') === true) {
            _('all', 'element').el().show()
        }
        if (this.selectionInput) {
            $(this.selectionInput).val(targetName)
        }
    }
}
