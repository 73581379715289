import {_} from '../utils'

export default class FeedbackQuestion {
    // Trigger - data-behaviour="feedback-question"
    // Target - data-element="feedback-field"
    constructor(el) {
        this.element = $(el)
        this.field = $('[data-element="feedback-field"]')
        this.field.hide()

        $(document).on('change', this.element, this.openFields)
    }

    openFields = (e) => {
        const selection = this.element[0].options[this.element[0].options.selectedIndex].text
        if (selection === 'Other') {
            $('[data-element="feedback-field"]').show()
        } else {
            $('[data-element="feedback-field"]').hide()
        }
    }
}
