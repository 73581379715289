const template = (data, label) => (
    `<div class="grid__item">
        ${data.map(option => `
            <div class="${option.selected ? 'button' : 'button button--link u-pl u-pr'}">
                <input data-behaviour="filter" type="radio" name="filter[${label}]" data-id="${option.id}" id="${option.name.replace(/\s+/g, '-').toLowerCase()}" name="group" class="sr-only" />
                <label class="button__label" for="${option.name.replace(/\s+/g, '-').toLowerCase()}">${option.name}</label>
            </div>
        `).join('')}
    </div>
`)

export default template
