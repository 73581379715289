const template = (data, label, key) => (
    `<div class="grid__item">
        <label class="field-label" for="filter[${label}]">Sort by ${label}:</label>
        <div class="custom-select">
            <select class="custom-select__select" name="filter[${key}]" id="filter[${label}]" data-behaviour="filter" data-element="filter">
            ${data.map(option => `<option value="${option.id}"${option.selected ? ' selected' : ''} id="${option.id}">${option.name}</option>`).join('')}
            </select>
            <svg width="10" height="9" class="custom-select__arrow">
                <use xlink:href="/application/themes/rawnet/app/images/sprite.svg#arrow"></use>
            </svg>
        </div>
    </div>
`)

export default template
